import { initializeApp } from "firebase/app";

const useFirebaseProduction =
  process.env.NODE_ENV === "production" ||
  process.env.REACT_APP_ENV === "production";
const config = useFirebaseProduction
  ? require("../config/firebase.production.json")
  : require("../config/firebase.staging.json");

export const firebaseApp = initializeApp(config);
