import React from "react";
import { countries } from "countries-list";

export default function CountryList({
  record = { countryAllowList: [], countryExcludeList: [] },
  source,
}: {
  record?: { countryAllowList: string[]; countryExcludeList: string[] };
  source: string;
}) {
  const countryAllowList =
    record.countryAllowList.map(
      (country) => country.toUpperCase() as keyof typeof countries
    ) || [];
  const countryExcludeList =
    record.countryExcludeList.map(
      (country) => country.toUpperCase() as keyof typeof countries
    ) || [];

  return (
    <div>
      <div>
        Allow:{" "}
        {countryAllowList.map((code, index) => {
          const country = countries[code];
          if (!country) {
            return null;
          }
          return (
            <>
              <span>
                {index > 0 ? ", " : ""}
                {country.name} {country.emoji}
              </span>
            </>
          );
        })}
      </div>
      <div>
        Exclude:{" "}
        {countryExcludeList.map((code, index) => {
          const country = countries[code];
          if (!country) {
            return null;
          }
          return (
            <>
              <span>
                {index > 0 ? ", " : ""}
                {country.name} {country.emoji}
              </span>
            </>
          );
        })}
      </div>
    </div>
  );
}
