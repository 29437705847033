import React, { useCallback, useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import { Title } from "react-admin";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  DragDropContext,
  Droppable,
  Draggable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import produce from "immer";
import { httpClient } from "../dataProvider";

import { API_URL } from "../constants";
import { urlForImage } from "../utils";
// DEBUG: fixture data are not enough to get a proper response for this
// endpoint, using production API instead.
// const API_URL = "https://api.photoroom.com/v1";

type Template = {
  id: string;
  imagePath: string;
  filterOnly: boolean;
  updatedAt: string;
  name: string;
  isPro: boolean;
  priority: number;
};

type Category = {
  id: string;
  displayName: string;
  templates: Template[];
};

function TemplateComponent({
  template,
  onChange,
  index,
}: {
  template: Template;
  onChange: (updatedTemplate: Template) => void;
  index: number;
}) {
  const [isHover, setIsHover] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  return template.filterOnly ? (
    <h3
      style={{
        marginRight: "16px",
      }}
    >
      {template.name}
    </h3>
  ) : (
    <Draggable draggableId={template.id} index={index}>
      {(provided) => {
        const imageUrl = urlForImage(template.imagePath);

        return (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={{
              position: "relative",
              marginRight: "16px",
              ...(!isLoaded && {
                width: "200px",
                height: "200px",
              }),
              ...provided.draggableProps.style,
            }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <img
              src={imageUrl}
              alt={template.name}
              style={{
                display: "block",
                maxWidth: "200px",
                maxHeight: "200px",
              }}
              loading="lazy"
              onLoad={() => {
                setIsLoaded(true);
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                position: "absolute",
                top: "4px",
                left: "4px",
                padding: "4px 8px 4px 4px",
                borderRadius: "4px",
                color: "white",
                transition: "background 0.2s",

                ...(isHover &&
                  !isError && {
                    background: "rgba(0, 0, 0, 0.8)",
                  }),

                ...(isHover &&
                  isError && {
                    background: "rgba(200, 10, 10, 0.8)",
                  }),
              }}
            >
              <input
                type="checkbox"
                id={`checkbox-ispro-${template.id}`}
                checked={template.isPro}
                onChange={() => {
                  // PATCH request to update template.isPro
                  httpClient(`${API_URL}/v2/templates/${template.id}/`, {
                    method: "PATCH",
                    body: JSON.stringify({
                      isPro: !template.isPro,
                      updatedAt: template.updatedAt,
                    }),
                  })
                    .then((updatedTemplate) => {
                      onChange(updatedTemplate.json);
                      setIsError(false);
                    })
                    .catch(() => {
                      setIsError(true);
                    });
                }}
              />
              <label
                htmlFor={`checkbox-ispro-${template.id}`}
                style={{
                  visibility: isHover ? "visible" : "hidden",
                }}
              >
                pro
              </label>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
}

const personas = ["Creator", "Smb", "Ecommerce", "Fun", "Reseller"];
type Persona = (typeof personas)[number];

type RecommendData = {
  results: Category[];
  next: string | null;
};

const PreviewHomepage = () => {
  const [persona, setPersona] = useState<Persona | undefined>(undefined);
  const [data, setData] = useState<RecommendData | undefined>(undefined);
  const [page, setPage] = useState<number>(1);

  const nextUrl = useCallback(
    (page: number) => {
      const search_params = new URLSearchParams();
      search_params.set("migration_method", "full");
      search_params.set("format", "json");
      search_params.set("page_size", "10");
      search_params.set("page", page.toString());

      if (persona !== undefined) {
        search_params.set("for_persona", persona);
      }

      return `${API_URL}/v2/recommend/?${search_params.toString()}&cacheBusting=${new Date().getTime()}`;
    },
    [persona]
  );

  useEffect(() => {
    fetch(nextUrl(1))
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setPage(2);
      });
  }, [persona, nextUrl]);

  const nextData = () => {
    if (data?.next !== null) {
      fetch(nextUrl(page))
        .then((response) => response.json())
        .then((nextData) => {
          setData((previousData) => ({
            ...nextData,
            results: [...(previousData?.results ?? []), ...nextData.results],
          }));
          setPage((page) => page + 1);
        });
    }
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    const source = result.source;
    const destination = result.destination;

    if (
      !destination ||
      source.droppableId !== destination.droppableId ||
      data === undefined
    ) {
      return;
    }

    const newData = produce(data, (draft) => {
      const sourceCategory = draft.results.find(
        (it) => it.id == source.droppableId
      );
      if (!sourceCategory) return;

      const destinationCategory = draft.results.find(
        (it) => it.id == destination.droppableId
      );
      if (!destinationCategory) return;

      const [template] = sourceCategory.templates.splice(source.index, 1);

      destinationCategory.templates.splice(destination.index, 0, template);

      // Compute new priority.  We want to have a priority that is
      // between the previous and next item, but we also want to
      // make sure that the priority is unique.
      const templates = destinationCategory.templates;
      const previous = templates[destination.index - 1];
      const next = templates[destination.index + 1];

      if (previous === undefined && next !== undefined) {
        template.priority = next.priority + 1;
      } else if (next === undefined && previous !== undefined) {
        template.priority = previous.priority - 1;
      } else if (previous !== undefined && next !== undefined) {
        template.priority = (previous.priority + next.priority) / 2;
      }
    });

    setData(newData);

    const newCategory = newData.results.find(
      (it) => it.id == destination.droppableId
    );
    if (!newCategory) return;
    const newTemplate = newCategory.templates[destination.index];

    // Update the priority on the server.
    httpClient(`${API_URL}/v2/templates/${newTemplate.id}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        priority: newTemplate.priority,
        updatedAt: newTemplate.updatedAt,
      }),
    })
      .then((response) => response.json)
      .then((updatedTemplate: Template) => {
        setData(
          produce((draft) => {
            if (draft !== undefined) {
              const category = draft.results.find(
                (it) => it.id == destination.droppableId
              );
              if (!category) return;
              category.templates[destination.index] = updatedTemplate;
            }
          })
        );
      })
      .catch((error) => {
        // If the update fails, revert the change.  Subject to race conditions
        // with other reverts, but there is no easy way to avoid that.
        setData(data);
      });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Card>
        <Title title="Homepage preview" />
        <CardContent>
          <FormControl>
            <InputLabel id="persona-select-label">Persona</InputLabel>
            <Select
              labelId="persona-select-label"
              id="persona-select"
              value={persona ?? "undefined"}
              label="Persona"
              onChange={(event) =>
                setPersona(event.target.value as Persona | undefined)
              }
            >
              <MenuItem value={"undefined"}>
                <span style={{ fontStyle: "italic", color: "#555555" }}>
                  None
                </span>
              </MenuItem>

              {personas.map((persona) => (
                <MenuItem key={persona} value={persona}>
                  {persona}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {data !== undefined && (
            <InfiniteScroll
              dataLength={Object.keys(data.results).length}
              next={nextData}
              hasMore={data.next !== null}
              loader={<h4>Loading...</h4>}
            >
              {data.results.map((category, categoryIndex) => (
                <div key={category.id}>
                  <h2>{category.displayName}</h2>
                  <Droppable
                    droppableId={category.id}
                    type={category.id}
                    direction="horizontal"
                  >
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ display: "flex" }}
                      >
                        {category.templates.map((template, templateIndex) => (
                          <TemplateComponent
                            key={template.id}
                            template={template}
                            index={templateIndex}
                            onChange={(updatedTemplate) => {
                              setData((previousData) =>
                                produce(previousData, (draft) => {
                                  if (draft !== undefined) {
                                    const updatedCategory = draft.results.find(
                                      (it) => it.id == category.id
                                    );
                                    if (updatedCategory) {
                                      updatedCategory.templates[templateIndex] =
                                        updatedTemplate;
                                    }
                                  }
                                })
                              );
                            }}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              ))}
            </InfiniteScroll>
          )}
        </CardContent>
      </Card>
    </DragDropContext>
  );
};

export default PreviewHomepage;
