import React from "react";
import { Login, useLogin } from "react-admin";
import { LoginProps } from "ra-ui-materialui/lib/auth/Login";
import { firebaseApp } from "../lib/firebase";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

export const LoginPage = (props: LoginProps) => {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const login = useLogin();

  const signInWithGoogle = () => {
    const auth = getAuth(firebaseApp);
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => result.user.getIdToken())
      .then((token) => login({ token }))
      .catch((error) => {
        setError(`Login failed: ${error}`);
      });
  };

  return (
    <Login {...props}>
      {error !== undefined && <p id="login-error">{error}</p>}
      <div onClick={signInWithGoogle} id="login-button">
        Sign in with Google
      </div>
    </Login>
  );
};
