import { getAuth } from "firebase/auth";
import { stringify } from "query-string";
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
  fetchUtils,
} from "react-admin";
import { firebaseApp } from "./lib/firebase";

const auth = getAuth(firebaseApp);

export const httpClient = async (
  url: string,
  options: fetchUtils.Options = {}
) => {
  if (!auth.currentUser) {
    await new Promise((r) => setTimeout(r, 4000)); // Hack to avoid empty user
    console.error("Could not find user");
  }
  const token = await auth.currentUser?.getIdToken();
  const appsessionid = localStorage.getItem("appsessionid");

  options.headers = new Headers({
    ...(options.headers ?? {}),
    Accept: "application/json",
    authorization: token ?? "",
    appsessionid:
      appsessionid && appsessionid !== "" ? appsessionid : "default",
  });

  return fetchUtils.fetchJson(url, options);
};

/**
 * Maps react-admin queries to the default format of Django REST Framework
 */
const drfProvider = (apiUrl: string) => {
  /**
   * @param {String} type React-admin request type, e.g. 'GET_LIST'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params Request parameters. Depends on the request type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHttp = (
    type: string,
    resource: string,
    params: any
  ) => {
    let url = "";
    let options: fetchUtils.Options = {};

    switch (type) {
      case CREATE:
        url = `${apiUrl}/${resource}/`;
        options.method = "POST";
        options.body = JSON.stringify(params.data);
        break;
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}/`;
        break;
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { filter } = params;
        const query = {
          page,
          page_size: perPage,
          ordering: `${order === "ASC" ? "" : "-"}${field}`,
          ...filter,
        };
        url = `${apiUrl}/${resource}/?${stringify(query)}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { filter, target, id } = params;
        const query = {
          page,
          page_size: perPage,
          ordering: `${order === "ASC" ? "" : "-"}${field}`,
          ...filter,
          [target]: id,
        };
        url = `${apiUrl}/${resource}/?${stringify(query)}`;
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}/`;
        options.method = "PUT";
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}/`;
        options.method = "DELETE";
        break;
      default:
        throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type React-admin request type, e.g. 'GET_LIST'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params Request parameters. Depends on the request type
   * @returns {Object} Data response
   */
  const convertHttpResponse = (
    response: {
      status: number;
      headers: Headers;
      body: string;
      json: any;
    },
    type: string,
    resource: string,
    params: any
  ) => {
    const { headers, json } = response;

    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if ("count" in json) {
          return { data: json.results, total: json.count };
        } else if (headers.has("content-range")) {
          return {
            data: json,
            total: parseInt(
              headers.get("content-range")?.split("/").pop() ?? "0",
              10
            ),
          };
        } else if ("detail" in json && json.detail === "Invalid page.") {
          return { data: [], total: 0 };
        } else {
          throw new Error(
            "The total number of results is unknown. The DRF data provider " +
              "expects responses for lists of resources to contain this " +
              "information to build the pagination. If you're not using the " +
              "default PageNumberPagination class, please include this " +
              'information using the Content-Range header OR a "count" key ' +
              "inside the response."
          );
        }
      case CREATE:
        return { data: { ...params.data, id: json.id } };
      case DELETE:
        return { data: params.previousData };
      default:
        return { data: json };
    }
  };

  /**
   * @param {String} type React-admin request type, e.g. 'GET_LIST'
   * @param {string} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type: string, resource: string, params: any) => {
    /**
     * Split GET_MANY, UPDATE_MANY and DELETE_MANY requests into multiple promises,
     * since they're not supported by default.
     */
    switch (type) {
      case GET_MANY:
        return Promise.all(
          params.ids.map((id: string) =>
            httpClient(`${apiUrl}/${resource}/${id}/`, {
              method: "GET",
            })
          )
        ).then((responses) => {
          const data = responses.map((response) => response.json);
          return {
            data,
          };
        });
      case UPDATE_MANY:
        return Promise.all(
          params.ids.map((id: string) =>
            httpClient(`${apiUrl}/${resource}/${id}`, {
              method: "PUT",
              body: JSON.stringify(params.data),
            })
          )
        ).then((responses) => ({
          data: responses.map((response) => response.json),
        }));
      case DELETE_MANY:
        return Promise.all(
          params.ids.map((id: string) =>
            httpClient(`${apiUrl}/${resource}/${id}`, {
              method: "DELETE",
            })
          )
        ).then((responses) => ({
          data: responses.map((response) => response.json),
        }));
      default:
        break;
    }

    const { url, options } = convertDataRequestToHttp(type, resource, params);
    return httpClient(url, options).then((response) =>
      convertHttpResponse(response, type, resource, params)
    );
  };
};

export default drfProvider;
