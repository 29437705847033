import { API_URL } from "./constants";

export const urlForImage = (imagePath: string) => {
  const endpoint =
    imagePath.startsWith("users") || imagePath.startsWith("team")
      ? "/v1/assets-cached-users.jpg"
      : "/v1/assets-cached.jpg";

  const url = API_URL + endpoint + "?path=" + imagePath;

  return url;
};
