import React, { Component } from "react";
import { addField } from "ra-core";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-github";

const styles = {};

type MarkdownInputProps = {
  input: { value: string; onChange: (value: string) => void };
};

type MarkdownInputState = {
  value: string;
  tab: string;
  editedValue: string;
};

class MarkdownInput extends Component {
  props: MarkdownInputProps;
  state: MarkdownInputState;

  constructor(props: MarkdownInputProps) {
    super(props);
    this.state = {
      value: "",
      tab: "write",
      editedValue: "",
    };
    this.props = props;
  }

  componentDidMount() {
    const {
      input: { value },
    } = this.props;

    this.setState({ value, editedValue: JSON.stringify(value, null, "\t") });
  }

  handleValueChange(stringValue: string) {
    try {
      let value = JSON.parse(stringValue);
      this.setState({ value: value, editedValue: stringValue });
      this.props.input.onChange(value);
    } catch (e) {
      console.error("Could not update json: invalid");
    }
  }

  render() {
    return (
      <FormControl fullWidth={true} className="ra-input-mde">
        <AceEditor
          mode="json"
          theme="github"
          value={this.state.editedValue || ""}
          onChange={(data) => this.handleValueChange(data)}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true, worker: false }}
        />
      </FormControl>
    );
  }
}

const RAEditorWithoutDefault = addField(withStyles(styles)(MarkdownInput));

const RAEditor = (props: any) => {
  return <RAEditorWithoutDefault addLabel={true} fullWidth={true} {...props} />;
};

export const JSONEditor2 = RAEditor;
