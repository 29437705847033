import React, { useEffect, useMemo, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Record, Title, useDataProvider } from "react-admin";
import { API_URL } from "../constants";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import { debounce } from "debounce";
import produce from "immer";

interface TemplateId {
  value: string;
  error: string | undefined;
  importing: boolean;
  imported: boolean;
  isPro: boolean;
  categoryId?: string;
}

function extractUuidFromString(str: string) {
  const uuids = str.match(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/);

  if (uuids !== null && uuids.length > 0) {
    return uuids[0];
  } else {
    return undefined;
  }
}

const TemplateThumbnail = ({ templateId }: { templateId: string }) => {
  const [src, setSrc] = useState<string | undefined>(undefined);
  const [error, setError] = useState<boolean>(false);

  const getSrc = useMemo(
    () =>
      debounce((templateId: string) => {
        const uuid = extractUuidFromString(templateId);
        if (uuid !== undefined) {
          setSrc(`${API_URL}/v1/get-preview.jpg?id=${uuid}`);
          setError(false);
        } else {
          setSrc(undefined);
          setError(false);
        }
      }, 500),
    []
  );

  React.useEffect(() => {
    getSrc(templateId);
  }, [templateId, getSrc]);

  const extraStyle = React.useMemo(() => {
    if (templateId.length === 0) {
      return {
        border: "1px #aaaaaa dashed",
      };
    } else if (src === undefined || error) {
      return {
        border: "1px #ff3333 dashed",
      };
    } else {
      return {};
    }
  }, [error, src, templateId]);

  return (
    <div
      style={{
        width: "120px",
        height: "120px",
        flexShrink: 0,
        boxSizing: "border-box",
        borderRadius: "4px",
        ...extraStyle,
      }}
    >
      {src !== undefined && !error && (
        <img
          width="120px"
          src={src}
          alt={`Template ${templateId} thumbnail`}
          onError={() => setError(true)}
        />
      )}
    </div>
  );
};

const ImportOfficialTemplates = () => {
  const [templateIds, setTemplateIds] = React.useState<TemplateId[]>([
    {
      value: "",
      error: undefined,
      importing: false,
      imported: false,
      isPro: false,
    },
  ]);

  const canImportTemplate = (templateId: TemplateId) =>
    !templateId.importing &&
    !templateId.imported &&
    templateId.value.length > 0;

  const dataProvider = useDataProvider();
  const [bulkCategory, setBulkCategory] = useState<Record | null>(null);
  const [templateCategories, setTemplateCategories] = useState<Record[]>([]);

  useEffect(() => {
    dataProvider
      .getList("v1/template-category", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then((response) => {
        setTemplateCategories(response.data);
      });
  }, [dataProvider]);

  return (
    <Card>
      <Title title="Import official templates" />
      <CardContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={9}>
            <Typography variant="body2" color="textSecondary" component="p">
              Paste template IDs or URLs of the user templates you want to
              import as official template.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <FormControl variant="filled" fullWidth style={{ maxWidth: 300 }}>
                <InputLabel style={{ whiteSpace: "nowrap" }}>
                  Set category for all
                </InputLabel>
                <Select
                  value={bulkCategory?.id ?? "undefined"}
                  onChange={(e) => {
                    const value =
                      e.target.value === "undefined"
                        ? undefined
                        : (e.target.value as string);
                    setBulkCategory(
                      templateCategories.find((c) => c.id === value) || null
                    );
                    setTemplateIds(
                      produce((draft) => {
                        draft.forEach((item) => (item.categoryId = value));
                      })
                    );
                  }}
                >
                  <MenuItem value={"undefined"}>
                    <i>Default category</i>
                  </MenuItem>
                  {templateCategories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          {templateIds.map((templateId, index) => (
            <Grid item key={index} xs={12}>
              <div
                style={{ display: "flex", gap: "16px", alignItems: "center" }}
              >
                <TemplateThumbnail templateId={templateId.value} />
                <TextField
                  label={`User template ID/URL ${index + 1}`}
                  value={templateId.value}
                  onChange={(event) => {
                    setTemplateIds(
                      produce((draft) => {
                        draft[index].value = event.target.value;
                      })
                    );
                    event.persist();
                  }}
                  onPaste={(e) => {
                    // Split pasted data by new line
                    const pastedLines = e.clipboardData
                      .getData("text")
                      .split(/\r?\n/)
                      .filter((item) => item !== "");

                    // If there is only one line, perform a regular paste,
                    // otherwise add as many line as necessary.
                    if (pastedLines.length > 1) {
                      // Add pasted data to templateIds
                      const newTemplateIds = [...templateIds];
                      newTemplateIds.splice(
                        index,
                        1,
                        ...pastedLines.map((item) => ({
                          value: item,
                          error: undefined,
                          importing: false,
                          imported: false,
                          isPro: false,
                        }))
                      );

                      setTemplateIds(newTemplateIds);
                      e.preventDefault();
                    }
                  }}
                  error={templateId.error !== undefined}
                  helperText={
                    templateId.importing
                      ? "Importing..."
                      : templateId.imported
                      ? "Imported"
                      : templateId.error
                  }
                  disabled={templateId.importing || templateId.imported}
                  fullWidth
                  variant="filled"
                  style={
                    templateId.imported
                      ? {
                          // Gray green
                          backgroundColor: "#e6f4ea",
                        }
                      : {}
                  }
                />
                <div style={{ flexShrink: "0" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={templateId.isPro}
                        onChange={(event) => {
                          setTemplateIds(
                            produce((draft) => {
                              draft[index].isPro = event.target.checked;
                            })
                          );
                          event.persist();
                        }}
                        color="primary"
                      />
                    }
                    label="Is pro"
                  />
                </div>

                <div>
                  <FormControl variant="filled">
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={templateId.categoryId ?? "undefined"}
                      onChange={(e) => {
                        const value =
                          e.target.value === "undefined"
                            ? undefined
                            : (e.target.value as string);
                        setTemplateIds(
                          produce((draft) => {
                            draft[index].categoryId = value;
                          })
                        );
                      }}
                    >
                      <MenuItem value={"undefined"}>
                        <i>Default category</i>
                      </MenuItem>
                      {templateCategories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      setTemplateIds(
                        produce((draft) => {
                          draft.splice(index, 1);

                          if (draft.length === 0) {
                            draft.push({
                              value: "",
                              error: undefined,
                              importing: false,
                              imported: false,
                              isPro: false,
                            });
                          }
                        })
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setTemplateIds(
                  produce((draft) => {
                    draft.push({
                      value: "",
                      error: undefined,
                      importing: false,
                      imported: false,
                      isPro: false,
                      categoryId: bulkCategory?.id.toString(),
                    });
                  })
                );
              }}
              style={{ marginRight: 8 }}
              startIcon={<AddIcon />}
            >
              Add ID/URL
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setTemplateIds((templateIds) => {
                  return templateIds.map((templateId) => {
                    if (!canImportTemplate(templateId)) {
                      return templateId;
                    }

                    return {
                      ...templateId,
                      importing: true,
                      error: undefined,
                    };
                  });
                });

                templateIds.forEach((templateId, index) => {
                  if (canImportTemplate(templateId)) {
                    const uuid = extractUuidFromString(templateId.value);

                    if (uuid === undefined) {
                      setTemplateIds(
                        produce((draft) => {
                          draft[index].importing = false;
                          draft[index].imported = false;
                          draft[index].error =
                            "Could not extract a valid template ID";
                        })
                      );
                      return;
                    }

                    fetch(`${API_URL}/v2/import-official-template`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        templateId: uuid,
                        isPro: templateId.isPro,
                        categoryId: templateId.categoryId,
                      }),
                    })
                      .then((response) => {
                        return response.json();
                      })
                      .then((data) => {
                        if (data.error) {
                          throw new Error(data.error);
                        }

                        setTemplateIds(
                          produce((draft) => {
                            draft[index].importing = false;
                            draft[index].imported = true;
                          })
                        );
                      })
                      .catch((error) => {
                        setTemplateIds(
                          produce((draft) => {
                            draft[index].importing = false;
                            draft[index].error = error.message;
                            draft[index].imported = false;
                          })
                        );
                      });
                  }
                });
              }}
              disabled={templateIds.some((templateId) => templateId.importing)}
              startIcon={<SaveIcon />}
            >
              Import
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ImportOfficialTemplates;
