import React from "react";
import { urlForImage } from "../utils";

export const ImageField = ({
  record,
  source,
  width = 120,
}: {
  record?: any;
  source: string;
  width?: number;
}) => {
  if (record[source]) {
    const url = urlForImage(record[source]);

    return <img width={`${width}px`} src={url} alt="" />;
  } else {
    return <img width={`${width}px`} src="" alt="" />;
  }
};
