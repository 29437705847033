export const API_URL =
  process.env.REACT_APP_API_URL || "https://api.photoroom.com";

export const LABELS = [
  "animal",
  "background",
  "bag",
  "bicycle",
  "bird",
  "boat",
  "bottle",
  "box",
  "building",
  "bus",
  "car",
  "cat",
  "chair",
  "clothing",
  "cow",
  "diningtable",
  "dog",
  "dress",
  "fashionaccessory",
  "flower",
  "food",
  "frame",
  "fruit",
  "furniture",
  "graphics",
  "handbag",
  "horse",
  "jacket",
  "motorbike",
  "object",
  "overlay",
  "person",
  "phone",
  "photo",
  "plane",
  "poster",
  "pottedplant",
  "shadow",
  "sheep",
  "shoes",
  "sofa",
  "text",
  "toy",
  "train",
  "tvmonitor",
  "vehicle",
  "watermark",
];
